import React, { useEffect, useState } from "react";

import "./Staffs.scss";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { useAuthenticate } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import NewPosition from "./NewPosition";

const StaffPosition = () => {
  const { user } = useAuthenticate();
  const [toggle, setToggle] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingStaffPositions, setLoadingStaffPositions] = useState(false);
  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);

  const [staffPositions, setStaffPositions] = useState([]);

 

  const deleteStaffPosition = (id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": `application/json`,
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };

          try {
            axios.delete(
              `${API_URL}administrator/staff-position/${id}`,
              config
            );
            Swal.fire(
              "Deleted!",
              "Staff Position has been deleted.",
              "success"
            ).then(() => {
              window.location.reload();
            });
          } catch (error) {
            if (error.response) {
              for (const property in error.response.data) {
                toast.error(
                  `${property} error: ${error.response.data[property]}`
                );
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      }
    });
  };


  useEffect(() => {
    const fetchStaffPositions = async () => {
      setLoadingStaffPositions(true);
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        try {
          const res = await axios.get(
            `${API_URL}administrator/staff-position/`,
            config
          );
          setLoadingStaffPositions(false);
          setStaffPositions(res.data);
        } catch (error) {}
      }
    };
    fetchStaffPositions();
  }, []);

  return (
    <div className="main-container">
      {
        modal && <NewPosition setModal={setModal} />
      }
      <Header title="Staff Positions" subtitle="Manage staff positions" />

      <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-5">
        
        {
          user?.account_type === "School Administrator" && (
            <div className="flex">
              
              <div
                onClick={()=>setModal(true)}
                className="cursor-pointer px-[10px] py-2 text-[12px] border border-light-primary bg-light-primary text-white "
              >
                Create Position
              </div>
            </div>
          )
        }
      </div>

      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            {!loadingStaffPositions && (
              <table className="min-w-full text-left text-sm font-light text-surface ">
                <thead className=" w-screen  font-medium">
                  <tr className="border-b border-t border-slate-200 text-black">
                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      SRL
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Position Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {staffPositions?.map((staff_position, index) => (
                    <tr
                      key={index}
                      className="text-[12px] border-l-2 border-transparent py-3 hover:border-primary hover:bg-slate-50 "
                    >
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {index + 1}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {staff_position?.position}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {loadingStaffPositions && (
              <div className="flex items-center justify-center py-5">
                <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
            {!loadingStaffPositions && staffPositions.length == 0 && (
              <p className="text-surface text-gray-400 py-10 text-center">
                No position found.
              </p>
            )}
          </div>
        </div>
      </div>

    </div>
  );
};

export default StaffPosition;
