import React, { useState, useEffect } from "react";

import { DataGrid } from "@mui/x-data-grid";
import Multiselect from "multiselect-react-dropdown";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import axios from "axios";
import { API_URL } from "../../url";
import Loading from "../../components/LoadingPage/Loading";
import AssignTeacherSubject from "./AssignTeacherSubject";
import Header from "../../components/Header";
import NoData from "../../components/NoData";


const TeacherSubjects = () => {
 
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [schoolTeachers, setSchoolTeachers] = useState([]);
  const [teacherSubjects, setTeacherSubjects] = useState([]);
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [schoolSubjects, setSchoolSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [teacherSubjectModal, setTeacherSubjectModal] = useState(false)

  // FETCH TEACHER SUBJECTS
  const fetchTeacherSubjects = async () => {
    if(selectedTeacher === ''){
      return toast.error("Please select a teacher")
    }
    if (localStorage.getItem("access")) {
      setLoadingData(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/teacher-subjects?teacher=${selectedTeacher}`,
          config
        );
        setTeacherSubjects(res.data);
        setLoadingData(false)
      } catch (error) {
        toast.error(error.response.data.message);
        setTeacherSubjects([]);
        setLoadingData(false);
      }
    }
  };

  

  // DELETE TEACHER SUBJECT
  const deleteTeacherSubject =  (id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        if(localStorage.getItem('access')){
          const config={
            headers:{
              'Content-Type': `application/json`,
              'Authorization':`JWT ${localStorage.getItem('access')}`,
              'Accept':'application/json'
            }
          }
          
    
          try{
            const res = axios.delete(`${API_URL}academics/teacher-subject/${id}`, config)
            
            Swal.fire(
              'Deleted!',
              'Teacher Assigned Subjects have been deleted.',
              'success'
            ).then(()=>{
              fetchTeacherSubjects()
            }
            )
            
          }catch(error){
            
            if (error.response){
              for (const property in error.response.data){
                toast.error(`${property} error: ${error.response.data[property]}`)
              }
            }else if(error.message){
              toast.error("Something went wrong. Please try again")
            }
          }

        }
        
      }
    })
  }


  const fetchTeachers = async () => {
    
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      await axios.get(
        `${API_URL}administrator/teachers/`,
        config
      ).then(response =>{
        setSchoolTeachers(response.data)
      }).catch(error => {
        
      })

      
    }
    
  };

  useEffect(()=>{
    fetchTeachers();
  }, [])
  
  return (
    
      <div className="main-container">
        <Header title="Teacher Subjects" subtitle="List of Teacher Subjects" />
        <div>
          {
            teacherSubjectModal && <AssignTeacherSubject schoolTeachers={schoolTeachers} schoolSubjects={schoolSubjects} setModal={setTeacherSubjectModal} />
          }
        <button className="px-3 py-2 bg-blue-500 text-white hover:bg-blue-400 rounded" onClick={()=>setTeacherSubjectModal(true)}>
          New Teacher Subject
        </button>
      </div>
      <div className="flex items-end justify-center gap-3 mt-4">
        <div className="flex flex-col">
          <label htmlFor="" className="font-semibold">Teacher</label>
          <select
            name=""
            id=""
            className="border border-gray-300 p-2 rounded"
            onChange={(e) => setSelectedTeacher(e.target.value)}
          >
            <option value="">Select Teacher</option>
            {schoolTeachers.map((teacher, index) => {
              return (
                <option value={teacher.staff_id} key={index}>
                  {teacher.user_account?.first_name} {teacher.user_account?.last_name}
                </option>
              );
            })}
          </select>
        </div>
        <button onClick={fetchTeacherSubjects} className="bg-primary text-white rounded px-3 py-2">
          Filter
        </button>
      </div>
      {loadingData ? (
        <Loading />
      ) : (
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-left text-sm font-light text-surface border border-gray-300">
                <thead className=" w-screen  font-medium">
                  <tr className="bg-gray-200 text-black">
                    <th scope="col" className="px-6 py-2 font-[500]">
                      Teacher
                    </th>

                    <th scope="col" className="px-6 py-2 font-[500]">
                      Subject
                    </th>
                    <th scope="col" className="px-6 py-2 font-[500]">
                      Classes
                    </th>
                    <th scope="col" className="px-6 py-2 font-[500]">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {teacherSubjects?.map((teacher_subject, index) => (
                    <tr key={index} className="border-b border-gray-300">
                      <td className="px-6 py-2 font-[300]">{teacher_subject?.teacher}</td>
                      <td className="px-6 py-2 font-[300]">{teacher_subject?.subject}</td>
                      <td className="px-6 py-2 font-[300]">{teacher_subject?.assigned_class?.map((school_class, index)=>(
                        <span>{school_class},</span>
                      ))}</td>
                      <td className="px-6 py-2 font-[300]">
                        <button
                          onClick={() => deleteTeacherSubject(teacher_subject?.id)}
                          className=" w-max px-3 py-2 font-[500] border border-red-400 text-red-400 rounded hover:bg-red-400 hover:text-white cursor-pointer"
                        >
                          Delete
                        </button>
                      </td>
                      
                    </tr>
                  ))}
                </tbody>
              </table>
              {
                !loading && teacherSubjects?.length === 0 && (
                  <NoData />
                )
              }
            </div>
          </div>
        </div>
      )}
        
      </div>
    
  );
};

export default TeacherSubjects;
