import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApp } from "./utils/helpers";


// export function App() {
  
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route exact path="" element={<Guest component={Login} />} />
//         <Route path="/*" element={<PrivateRoutes component={Layout}/>} />
        
//       </Routes>
//       <ToastContainer
//         position="top-right"
//         autoClose={6608}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="dark"
//       />
//     </BrowserRouter>
//   );
// }

// export function SuperAdmin(){
  
//   return(
//     <Routes>
//       <Route path="/" element={<SuperAdmin />} />
//     </Routes>
//   )
// }

// export default App;


export const App = () => {
  const CurrentApp = getApp();
  
  return(
    <div>
      <BrowserRouter>
        <CurrentApp />
        <ToastContainer
          position="top-right"
          autoClose={6608}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </BrowserRouter>
    </div>
  )
}

export default App;