import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../url";
import { Link } from "react-router-dom";
import NewAcademicYearModal from "./NewAcademicYearModal";
import Header from "../../components/Header";
import { useAuthenticate } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import UpdateAcademicYear from "./UpdateAcademicYear";

const AcademicYears = () => {
  const { user } = useAuthenticate();
  const [academicYears, setAcademicYears] = useState([]);
  const [academicYearModal, setAcademicYearModal] = useState(false);
  const [gettingAcademicYears, setGettingAcademicYears] = useState(false);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(null);
  const [updateModal, setUpdateModal] = useState(false)

  const fetchAcademicYears = async () => {
    if (localStorage.getItem("access")) {
      setGettingAcademicYears(true);
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      await axios
        .get(`${API_URL}academics/academic-years/`, config)
        .then((response) => {
          setAcademicYears(response.data);
          setGettingAcademicYears(false);
        })
        .catch((error) => {
          toast.error("Failed to get academic years. Please try again.");
          setGettingAcademicYears(false);
        });
    }
  };

  useEffect(() => {
    fetchAcademicYears();
  }, []);

  return (
    <div className="main-container">
      <Header
        title={"Academic Years"}
        subtitle="Get and manage school academic years"
      />

      {academicYearModal && (
        <NewAcademicYearModal setAcademicYearModal={setAcademicYearModal} />
      )}

{updateModal && (
        <UpdateAcademicYear setAcademicYearModal={setUpdateModal} academicYear = {selectedAcademicYear} />
      )}
      <div>
        {user?.position === "Administrator" && (
          <button
            className="px-3 py-2 bg-secondary text-secondary-white hover:bg-secondary-100 rounded"
            onClick={() => setAcademicYearModal(true)}
          >
            Add New Academic Year
          </button>
        )}
      </div>

      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-left text-sm font-light text-surface border border-gray-300">
              <thead className=" w-screen  font-medium">
                <tr className="bg-gray-200 text-black">
                  <th scope="col" className="px-6 py-2 font-[500]">
                    Academic Year
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Start Date
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    End Date
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Status
                  </th>

                  <th scope="col" className="px-6 py-2 font-[500]">
                    Actions
                  </th>
                </tr>
              </thead>
              {!gettingAcademicYears && (
                <tbody>
                  {academicYears?.map((academic_year, index) => (
                    <tr key={index} className="border-b border-gray-300">
                      <td className="px-6 py-2 font-[300]">
                        {academic_year?.name}
                      </td>
                      <td className="px-6 py-2 font-[300]">
                        {academic_year?.start_date}
                      </td>
                      <td className="px-6 py-2 font-[300]">
                        {academic_year?.end_date}
                      </td>
                      <td className="px-6 py-2 font-[300]">
                        {academic_year?.is_current_year && (
                          <div className="bg-green-600 text-white w-max rounded px-2 py-1 text-[14px]">
                            Current Year
                          </div>
                        )}
                      </td>
                      <td className="flex items-center gap-2">
                        <Link
                          to={`/academics/academic-year/${academic_year?.id}/`}
                          className=" w-max px-3 py-2 font-[500] border border-blue-400 text-blue-400 rounded hover:bg-blue-400 hover:text-white cursor-pointer"
                        >
                           Details
                        </Link>
                        <div
                          onClick={()=>{
                            setSelectedAcademicYear(academic_year)
                            setUpdateModal(true)
                          }}
                          className=" w-max px-3 py-2 font-[500] border bg-blue-400 text-white rounded hover:bg-blue-400 hover:text-white cursor-pointer"
                        >
                          Edit
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {gettingAcademicYears && (
              <div className="flex items-center justify-center py-5">
                <RotatingLines strokeColor="black" height="20" width="20" />
              </div>
            )}
            {!gettingAcademicYears && academicYears?.length == 0 && (
              <p className="text-center">No Academic Year Found!</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademicYears;
