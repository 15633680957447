import React, { useEffect, useState } from "react";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { Route, Routes } from "react-router-dom";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Students from "../../pages/Students/Students";
import { FaBars } from "react-icons/fa";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import AdmissionList from "../../pages/Admissions/AdmissionList";
import AddStudent from "../../pages/Students/AddStudent";
import ClassList from "../../pages/Class/ClassList";
import AcademicYears from "../../pages/Academics/AcademicYears";
import AcademicTerms from "../../pages/Academics/AcademicTerms";
import AddStaff from "../../pages/Staffs/AddStaff";
import StaffList from "../../pages/Staffs/StaffList";
import SubjectList from "../../pages/Subjects/SubjectList";
import ClassSubjects from "../../pages/Subjects/ClassSubjects";
import TeacherSubjects from "../../pages/Subjects/TeacherSubjects";
import ClassSchedule from "../../pages/Class/ClassSchedule";
import Events from "../../pages/Events/Events";
import EventDetail from "../../pages/Events/EventDetail";
import AssessmentTypes from "../../pages/Assessments/AssessmentTypes";
import StudentReportScheme from "../../pages/Assessments/StudentReportScheme";
import FeesGroups from "../../pages/Fees/FeeGroups";
import FeeAllocation from "../../pages/Fees/FeeAllocation";
import Mailbox from "../../pages/Mailbox/Mailbox";
import SchoolInfo from "../../pages/Settings/SchoolInfo";
import Grading from "../../pages/Settings/Grading";
import ClassAttendance from "../../pages/Attendance/ClassAttendance";
import ExamSchedules from "../../pages/Assessments/ExamSchedules";
// import Page404 from "./components/PageErrors/Page404";
import StaffPosition from "../../pages/Staffs/StaffPosition";
import ClassFees from "../../pages/Fees/ClassFees";
import StudentFees from "../../pages/Fees/StudentFees";
import StudentFeeAllocation from "../../pages/Fees/StudentFeeAllocation";
import StudentAssessmentMarks from "../../pages/Assessments/StudentAssessmentMarks";
import StudentProfile from "../../pages/Students/StudentProfile";
import StaffProfile from "../../pages/Staffs/StaffProfile";
import StudentExamResults from "../../pages/Assessments/StudentExamResults";
import GenerateStudentReport from "../../pages/Assessments/GenerateStudentReport";
import FeesCollection from "../../pages/Fees/FeesCollection";
import AddMultipleStudents from "../../pages/Students/AddMultipleStudents";
import PromoteStudents from "../../pages/Class/PromoteStudents";
import Profile from "../../pages/Profile/Profile";
import Subscription from "../../pages/Subscription/Subscription";
import "./Layout.scss";
import Overview from "../../pages/Finance/Overview";
import Income from "../../pages/Finance/Income";
import Expense from "../../pages/Finance/Expense";
import ReceiveIncome from "../../pages/Finance/ReceiveIncome";
import AddMultipleStaff from "../../pages/Staffs/AddMultipleStaff";
import RecordExpense from "../../pages/Finance/RecordExpense";
import IncomeStatement from "../../pages/Finance/IncomeStatement";
import IncomeList from "../../pages/Finance/IncomeList";
import ExpenseList from "../../pages/Finance/ExpenseList";
import ClassDetails from "../../pages/Class/ClassDetails";
import AcademicYearDetails from "../../pages/Academics/AcademicYearDetails";
import WeeklyAttendance from "../../pages/Attendance/WeeklyAttendance";
import PreschoolReport from "../../pages/EYFS/PreschoolReport";
import AssignReport from "../../pages/EYFS/AssignReport";
import EyfsObjectives from "../../pages/EYFS/EyfsObjectives";
import EyfsStudentReport from "../../pages/EYFS/EyfsStudentReport";
import StudentTermMarks from "../../pages/Assessments/StudentTermMarks";
import GraduateStudents from "../../pages/Graduation/GraduateStudents";
import Graduates from "../../pages/Graduation/Graduates";
import GraduateDetails from "../../pages/Graduation/GraduateDetails";
import MakeSubscriptionPayment from "../../pages/Subscription/MakeSubscriptionPayment";
import { useAuthenticate } from "../../context/AuthContext";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import FeesDebtors from "../../pages/Fees/FeesDebtors";
import Drivers from "../../pages/Transport/Drivers";
import Vehicles from "../../pages/Transport/Vehicles";
import StudentVehicle from "../../pages/Transport/StudentVehicle";
import AssignStudentVehicle from "../../pages/Transport/AssignStudentVehicle";
import AddParent from "../../pages/Parents/AddParent";
import TeacherSchedules from "../../pages/Teachers/TeacherSchedules";
import UserSchedules from "../../pages/Teachers/UserSchedules";
import MarksEntry from "../../pages/Assessments/MarksEntry";
import MarkAttendance from "../../pages/Attendance/MarkAttendance";
import StudentAttendance from "../../pages/Attendance/StudentAttendance";
import AcademicReportCard from "../../pages/Assessments/Student/AcademicReportCard";
import StudentExamSchedule from "../../pages/Assessments/Student/StudentExamSchedule";
import MainExamMarks from "../../pages/Assessments/Student/MainExamMarks";
import YourFees from "../../pages/Fees/Student/YourFees";
import SchoolsList from "../../pages/Schools/SchoolsList";
import AddSchool from "../../pages/Schools/AddSchool";
import SuperadminRoutes from "../../routes/SuperadminRoutes";
import SchoolAdminRoutes from "../../routes/SchoolAdminRoutes";
import TeacherDashboard from "../../pages/Dashboard/TeacherDashboard";
import StudentRoutes from "../../routes/StudentRoutes";
import TeacherRoutes from "../../routes/TeacherRoutes";
import Collectibles from "../../pages/Collectibles/Collectibles";
import DailyCollectibles from "../../pages/Collectibles/DailyCollectibles";
import AccountantDashboard from "../../pages/Dashboard/AccountantDashboard";
import StudentFeePayments from "../../pages/Fees/StudentFeePayments";
import SubscriptionPackages from "../../pages/Subscription/SubscriptionPackages";
import ParentManagement from "../../pages/Parents/ParentManagement";
import NewEvent from "../../pages/Events/NewEvent";
import EditEvent from "../../pages/Events/EditEvent";

const Layout = () => {
  const [open, setOpen] = useState(true);
  const { schoolInfo } = useAuthenticate();

  const dispatch = useDispatch();
    const logoutUser = (e) => {
        dispatch(logout())
        window.location.href="/"
    }

  // useEffect(()=>{
  //   if(schoolInfo.package === "Free" && schoolInfo.subscription_days_left < 0){
  //     Swal.fire({
  //       title:"Subscription Expired!",
  //       text:"Unfortunately, your free trial has ended.",
  //       icon:"error"
  //     }).then(()=>{
  //       logoutUser()
  //     })
  //   }
  // },[])
  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <div className="main-container" style={{ paddingLeft: open ? "270px" : "0px" }}>
      <Sidebar isOpen={open} />
      <div style={{ paddingTop: "50px" }}>
          {
            schoolInfo?.package === "Free" && (
              <div
          className="subscription-alert"
          style={{
            background: "#5BB1F2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            padding: "10px",
          }}
        >
          You have {schoolInfo?.subscription_days_left} days left on your free package.
        </div>
            )
          }
        
        <div className="main-page">
          <Topbar icon={<FaBars onClick={toggleOpen} />} />
          
          
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/accountant/dashboard" element={<AccountantDashboard />} />
            <Route path="/profile" element={<Profile />} />

            {/* PARENTS */}
            <Route path="/parent-management" element={<ParentManagement />} />

            {/* STUDENTS */}
            <Route path="/students/student-list" element={<Students />} />
            <Route path="/students/add-student" element={<AddStudent />} />
            <Route
              path="/students/add-multiple-student/"
              element={<AddMultipleStudents />}
            />
            <Route path="/student/:id" element={<StudentProfile />} />

            

            {/* CLASSES */}
            <Route path="/class/class-list" element={<ClassList />} />
            <Route path="/class/:id/" element={<ClassDetails />} />
            <Route path="/class/class-schedule" element={<ClassSchedule />} />
            <Route path="subjects/subject-list/" element={<SubjectList />} />
            <Route
              path="/class/promote-students/"
              element={<PromoteStudents />}
            />
            

            {/* SUBJECTS */}
            <Route
              path="subjects/class-subjects/"
              element={<ClassSubjects />}
            />
            <Route
              path="subjects/teacher-subjects/"
              element={<TeacherSubjects />}
            />
            {/* <Route path="/admissions" element={<AdmissionList />} /> */}

            {/* GRADUATION */}
            <Route
              path="/graduation/graduate-students"
              element={<GraduateStudents />}
            />
            <Route path="/graduation/graduation-list" element={<Graduates />} />
            <Route
              path="/graduation/graduate-details"
              element={<GraduateDetails />}
            />

            {/* ACADEMIC YEARS AND TERMS */}
            <Route
              path="/academics/academic-years/"
              element={<AcademicYears />}
            />
            <Route
              path="/academics/academic-year/:id/"
              element={<AcademicYearDetails />}
            />
            <Route
              path="/academics/academic-terms/"
              element={<AcademicTerms />}
            />

            {/* EVENTS */}
            <Route path="/events" element={<Events />} />
            <Route path="/events/:id" element={<EventDetail />} />
            <Route path="/new-event/" element={<NewEvent />} />
            <Route path="/events/:id/edit" element={<EditEvent />} />

            {/* ASSESSMENTS */}
            <Route
              path="/assessments/assessment-types"
              element={<AssessmentTypes />}
            />

            <Route path="/eyfs/objectives" element={<EyfsObjectives />} />
            <Route
              path="/eyfs/pre-school-report"
              element={<PreschoolReport />}
            />
            <Route path="/eyfs/assign-eyfs-report" element={<AssignReport />} />
            <Route
              path="/eyfs/student-report/"
              element={<EyfsStudentReport />}
            />
            <Route
              path="/assessments/student-report-scheme"
              element={<StudentReportScheme />}
            />
            <Route
              path="/assessments/student-assessment-marks"
              element={<StudentAssessmentMarks />}
            />
            <Route
              path="/assessments/student-exam-results"
              element={<StudentExamResults />}
            />
            <Route
              path="/assessments/generate-student-report/"
              element={<GenerateStudentReport />}
            />
            <Route
              path="/assessments/student-term-marks/"
              element={<StudentTermMarks />}
            />
            <Route path="/exams/exam-schedule" element={<ExamSchedules />} />
            <Route path="/assessments/marks-entry" element={<MarksEntry />} />
            <Route path="/student/report-card" element={<AcademicReportCard />} />
            <Route path="/student/exam-schedule" element={<StudentExamSchedule />} />
            <Route path="/student/main-exam-marks" element = {<MainExamMarks />} />

            {/* FEES */}
            <Route path="/fees/fees-groups" element={<FeesGroups />} />
            <Route path="/fees/fees-allocation" element={<FeeAllocation />} />
            <Route path="/fees/class-fees" element={<ClassFees />} />
            <Route path="/fees/student-fees" element={<StudentFees />} />
            <Route
              path="/fees/student-fees-allocation"
              element={<StudentFeeAllocation />}
            />
            <Route path="/fees/fee-collection" element={<FeesCollection />} />
            <Route path="/fees/fees-debtors" element={<FeesDebtors />} />
            <Route path="/fees/payments" element={<StudentFeePayments />} />

            {/* STUDENT FEES */}
            <Route path="/student/fees" element={<YourFees />} />


            {/* COLLECTIBLES */}
            <Route path="/account/collectibles" element={<Collectibles />} />
            <Route path="/account/daily-collectibles" element={<DailyCollectibles />} />

            {/* COMMUNICATION */}
            <Route path="/communications/mailbox" element={<Mailbox />} />

            {/* SCHOOL SETTINGS */}
            <Route path="/settings/school-info" element={<SchoolInfo />} />
            <Route path="/settings/grading" element={<Grading />} />
            <Route path="/settings/subscription" element={<Subscription />} />
            <Route path="/settings/subscription/select-plan/" element={<SubscriptionPackages />} />
            <Route
              path="/settings/subscription-payment"
              element={<MakeSubscriptionPayment />}
            />

            {/* ATTENDANCE */}
            {/* <Route
              path="/attendance/class-attendance"
              element={<ClassAttendance />}
            /> */}
            <Route
              path="/attendance/class-weekly-attendance"
              element={<WeeklyAttendance />}
            />
            <Route path="/attendance/mark-attendance" element={<MarkAttendance />} />
            <Route path="/student-attendance" element={<StudentAttendance />} />


            {/* SCHOOL FINANCE */}
            <Route path="/finance/overview/" element={<Overview />} />
            <Route path="/finance/income/" element={<Income />} />
            <Route path="/finance/expense/" element={<Expense />} />
            <Route
              path="/finance/receive-income/"
              element={<ReceiveIncome />}
            />
            <Route
              path="/finance/record-expense/"
              element={<RecordExpense />}
            />
            <Route
              path="/finance/income-statement/"
              element={<IncomeStatement />}
            />
            <Route path="/finance/income-list/" element={<IncomeList />} />
            <Route path="/finance/expense-list/" element={<ExpenseList />} />
            

            {/* TRANSPORT */}
            <Route path="/transport/drivers/" element={<Drivers />} />
            <Route path="/transport/vehicles/" element={<Vehicles /> } />
            <Route path="transport/assign-student/" element={<StudentVehicle />} />
            <Route path="transport/assign-student-vehicle/" element={<AssignStudentVehicle />} />

            {/* TEACHERS */}
            <Route path="/teachers/teacher-schedule" element={<TeacherSchedules />} />
            

            {/* SUPER ADMIN ROUTES */}
            <Route path="/superadmin/*" element={<SuperadminRoutes />} />
            
            {/* SCHOOL ADMIN ROUTES */}
            <Route path="/admin/*" element={<SchoolAdminRoutes />} />

            {/* STAFF ROUTES */}
            <Route path='/staff/*' element={<TeacherRoutes />} />

            {/* STUDENT ROUTES */}
            <Route path="/std/*" element={<StudentRoutes />} />
          </Routes>
        </div>
      </div>
      <TawkMessengerReact
                propertyId="67ad2f8b3a842732607e0330"
                widgetId="1iju83egc"/>
    </div>
  );
};

export default Layout;
