import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../context/AuthContext";
import Header from "../../components/Header";
import NoData from "../../components/NoData";
import NewObjective from "./NewObjective";
import UpdateObjective from "./UpdateObjective";


const EyfsObjectives = () => {
  const {user} = useAuthenticate()
  const [modal, setModal] = useState(false)
  const [eyfsObjectives, setEyfsObjectives] = useState([]);
  const [updateModal, setUpdateModal] = useState(false)
  
  const [data, setData] = useState({
    objective_name: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedObjective, setSelectedObjective] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  
  //   FETCH EYFS OBJECTIVES
  const fetchEyfsObjectives = async () => {
    setLoadingData(true);
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/eyfs/objectives/`,
          config
        );
        setEyfsObjectives(res.data);
        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  const columns = [
    { field: "objective_name", headerName: "Objective Name", width: 700 },
  ];

  useEffect(() => {
    fetchEyfsObjectives();
  }, []);
  return (
    <div className="main-container">
      {
        modal && <NewObjective setModal={setModal} />
      }
      {
        updateModal && <UpdateObjective setModal={setUpdateModal} objective={selectedObjective} />
      }
      <Header title="EYFS Objectives" subtitle="Early Years Foundation Stage Objectives Management" />
      
      <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-5">
        
        {
          user?.account_type === "School Administrator" && (
            <div className="flex">
              
              <div
                onClick={()=>setModal(true)}
                className="cursor-pointer px-[10px] py-2 text-[12px] border border-light-primary bg-light-primary text-white "
              >
                Create Objective
              </div>
            </div>
          )
        }
      </div>

        
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            {!loadingData && (
              <table className="min-w-full text-left text-sm font-light text-surface ">
                <thead className=" w-screen  font-medium">
                  <tr className="border-b border-t border-slate-200 text-black">
                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      SRL
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Objective
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {eyfsObjectives?.map((objective, index) => (
                    <tr
                      key={index}
                      className="text-[12px] border-l-2 border-transparent py-3 hover:border-primary hover:bg-slate-50 "
                    >
                    <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {index + 1}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {objective?.objective_name}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        <div className="cursor-pointer px-2 py-1 bg-blue-500 rounded text-white w-max" onClick={()=>{
                          setSelectedObjective(objective)
                          setUpdateModal(true)
                        }}>
                          Update
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {loadingData && (
              <div className="flex items-center justify-center py-5">
                <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
            {!loadingData && eyfsObjectives.length == 0 && (
              <NoData />
            )}
          </div>
        </div>
      </div>

      {/* <div className="class-body">
        {
          user?.account_type === "School Administrator" && (
            <div className="left">
              <p>Add EYFS Objective</p>
              <form onSubmit={(e) => addObjective(e)}>
                <div className="classFormGroup">
                  <label>Objective Name*</label>
                  <textarea
                    name="objective_name"
                    onChange={(e) => handleChange(e)}
                    id="objective_name"
                    cols="30"
                    rows="8"
                  ></textarea>
                  
                </div>
                {loading ? (
                  <button className="submitButton">
                    <ColorRing
                      visible={true}
                      height="20"
                      width="20"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                    />{" "}
                    Adding Objective{" "}
                  </button>
                ) : (
                  <input
                    disabled={data.objective_name === ""}
                    className="submitButton"
                    type="submit"
                    value="Add Objective"
                  />
                )}
              </form>
            </div>
          )
        }
        <div className="right">
          <DataGrid
            rows={eyfsObjectives}
            columns={columns}
            loading={loadingData}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </div>
      </div> */}
    </div>
  );
};

export default EyfsObjectives;
