import React, { useEffect, useState } from "react";
import "./AddClassScheduleModal.scss";
import axios from "axios";
import { API_URL } from "../../url";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";
import { useAuthenticate } from "../../context/AuthContext";

const AddClassScheduleModal = () => {
  const { academicYears, schoolClasses } = useAuthenticate();
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [academicTerms, setAcademicTerms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState([
    {
      teacher: "",
      subject: "",
      start_time: "",
      end_time: "",
      is_break: "false",
    },
  ]);
  const [data, setData] = useState({
    related_class: "",
    term: "",
    academic_year: "",
  });

  const fetchSchoolTeachers = async () => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}administrator/teachers/`,
          config
        );
        setTeachers(res.data);
      } catch (error) {
        
      }
    }
  };

  // FETCH CLASS TERM SUBJECTS
  const fetchClassTermSubjects = async (e) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}academics/class-term-subjects/${data.related_class}/`,
          config
        );
        setSubjects(res.data);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  // CHANGE ACADEMIC YEAR
  const changeAcademicYear = (e) => {
    handleChange(e);

    if (e.target.value !== "") {
      setAcademicTerms(
        academicYears.find((year) => year.id === parseInt(e.target.value)).terms
      );
    }
  };

  // CHANGING FORM FIELDS FOR EXTRA SCHEDULE DETAILS
  const handleFormFieldsChange = (e, index) => {
    const formData = [...formFields];
    formData[index][e.target.name] = e.target.value;
    setFormFields(formData);
  };

  // ADD MORE SCHEDULE DETAILS FIELDS
  const addFields = () => {
    const object = {
      teacher: "",
      subject: "",
      start_time: "",
      end_time: "",
      is_break: "false",
    };
    setFormFields([...formFields, object]);
  };

  // REMOVE SCHEDULE DETAIL FIELDS
  const removeFields = (index) => {
    const formFieldData = [...formFields];
    formFieldData.splice(index, 1);
    setFormFields(formFieldData);
  };

  // UPDATE DATA TO BE SUBMITTED
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": `application/json`,
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      const body = JSON.stringify({
        ...data,
        schedule_details: formFields,
      });
      try {
        const res = await axios.post(
          `${API_URL}administrator/class-schedules/`,
          body,
          config
        );

        setLoading(false);
        if (res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Class Schedule Created Successfully",
          }).then(() => {
            window.location.reload();
          });
        }
      } catch (error) {
        setLoading(false);
        if (error.response) {
          
          if (error.response.status === 401) {
            window.location.href = "/";
          }

          for (const property in error.response.data) {
            toast.error(error.response.data[property]);
          }
        } else {
          toast.error("Something Went Wrong");
        }
      }
    }
  };

  useEffect(()=>{
    fetchSchoolTeachers()
  },[])

  return (
    <div className="exam-schedule">
      <div className="exam-schedule-content">
        <div className="exam-schedule-header">
          <p>Add Class Schedule</p>
        </div>
        <small>All Fields with * are required</small>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="formSection">
            <div className="formRow">
              <div className="formInput">
                <label>Day*</label>
                <select required name="day" onChange={(e) => handleChange(e)}>
                  <option value="">Select Day</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                </select>
              </div>
              <div className="formInput">
                <label>Class*</label>
                <select
                  required
                  name="related_class"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">Select Class</option>
                  {schoolClasses.map((schoolClass) => {
                    return (
                      <option value={schoolClass.id} key={schoolClass.id}>
                        {schoolClass.class_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="formRow">
              <div className="formInput">
                <label>Academic Year*</label>
                <select
                  required
                  name="academic_year"
                  id="academic_year"
                  onChange={(e) => {
                    changeAcademicYear(e);
                    fetchClassTermSubjects(e);
                  }}
                >
                  <option value="">Select Academic Year</option>
                  {academicYears.map((academicYear) => {
                    return (
                      <option value={academicYear.id} key={academicYear.id}>
                        {academicYear.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="formInput">
                <label>Academic Term*</label>
                <select
                  name="term"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  id="academic_term"
                >
                  {data.academic_year === "" ? (
                    <option value="">Select Academic Year First</option>
                  ) : (
                    <>
                      <option value="">Select Term</option>
                      {academicTerms.map((academicTerm) => {
                        return (
                          <option key={academicTerm.id} value={academicTerm.id}>
                            {academicTerm.name}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
            </div>
          </div>
          {formFields.map((formField, index) => {
            return (
              <div key={index} className="formSection">
                <div className="sectionHeader">
                  <p>Schedule Detail</p>
                  <div
                    style={{
                      color: "red",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => removeFields(index)}
                  >
                    <AiFillDelete />
                  </div>
                </div>
                <div className="formRow">
                  {/* TEACHER */}
                  <div className="formInput">
                    <label>Teacher</label>
                    <select
                      name="teacher"
                      onChange={(e) => handleFormFieldsChange(e, index)}
                    >
                      <option>Select Teacher</option>
                      {teachers?.map((teacher) => {
                        return (
                          <option
                            value={teacher.staff_id}
                            key={teacher.staff_id}
                          >
                            {teacher?.user_account?.first_name} {teacher?.user_account?.last_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* SUBJECT */}
                  <div className="formInput">
                    <label>
                      Subject{" "}
                      {formFields[index]["is_break"] === "false" ? "*" : ""}
                    </label>
                    <select
                      name="subject"
                      onChange={(e) => handleFormFieldsChange(e, index)}
                      required={
                        formFields[index]["is_break"] === "false" ? true : false
                      }
                    >
                      {data.related_class === "" ? (
                        <option value="">Select Class First</option>
                      ) : (
                        <>
                          <option value="">Select Subject</option>
                          {subjects.map((subject) => {
                            return (
                                <option value={subject.id} key={subject.id}>
                                  {subject.subject_name}
                                </option>
                            );
                          })}
                          <option value="activity">Activity</option>
                        </>
                      )}
                    </select>
                  </div>
                  {/* ACTIVITY */}
                  {formFields[index]["subject"] === "activity" && (
                    <div className="formInput">
                      <label>Activity Name </label>
                      <input
                        type="text"
                        name="activity"
                        onChange={(e) => handleFormFieldsChange(e, index)}
                      />
                    </div>
                  )}

                </div>
                <div className="formRow">
                  <div className="formInput">
                    <label>Start Time*</label>
                    <input
                      type="time"
                      name="start_time"
                      onChange={(e) => handleFormFieldsChange(e, index)}
                      required
                    />
                  </div>
                  <div className="formInput">
                    <label>End Time*</label>
                    <input
                      type="time"
                      name="end_time"
                      onChange={(e) => handleFormFieldsChange(e, index)}
                      required
                    />
                  </div>
                </div>
                <div className="formInputGroup">
                  <div style={{ display: "flex", gap: "5px" }}>
                    <input
                      type="checkbox"
                      name="is_break"
                      onChange={(e) => {
                        const fData = [...formFields];
                        fData[index][e.target.name] = e.target.checked;
                        setFormFields(fData);
                      }}
                    />{" "}
                    Break (specify whether this period is break time or not)
                  </div>
                </div>
              </div>
            );
          })}
          {loading ? (
            <button
              style={{
                backgroundColor: "gray",
                cursor: "wait",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                border: "none",
                padding: "10px",
                color: "white",
                margin: "auto",
              }}
            >
              <ColorRing
                visible={true}
                height="20"
                width="20"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />{" "}
              Adding Class Schedule
            </button>
          ) : (
            <div className="schedule-buttons">
              <div onClick={addFields} className="addBtn">
                Add Another Schedule
              </div>
              <button style={{ background: "rgb(71, 169, 146)" }} type="submit">
                Submit Schedule
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddClassScheduleModal;
