import axios from "axios";
import React, { useState, useEffect } from "react";
import { API_URL } from "../../url";
import { toast } from "react-toastify";
import Loading from "../../components/LoadingPage/Loading";
import NoDataImage from "../../assets/no_data.jpg"
import Header from "../../components/Header";

const UserSchedules = () => {
  const [teacherSchedules, setTeacherSchedules] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTeacherSchedules = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${API_URL}teacher/schedules/`, config)
      .then((response) => {
        setTeacherSchedules(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("An error occured. Please try again later");
        console.log(error);
      });
  };

  useEffect(()=>{
    getTeacherSchedules();
  },[])
  return (
    <div className="main-container">
      <Header title="Your Schedules" subtitle="Get Your Class Schedules" />
      {
          loading ? (
              <Loading />
        ):(
            <div className="schedule-body">
                {/* IF NO RESULT IS FOUND DISPLAY A NOT FOUND MESSAGE ELSE DISPLAY THE RESULTS */}
                {teacherSchedules && teacherSchedules.length > 0 ? (
                <div className="schedule">
                    {/* <ReactToPrint
                                trigger={() => (
                                <button className="export-button">
                                    Export To PDF
                                </button>
                                )}
                                content={()=> scheduleRef.current}
                                documentTitle = "Class Time Table"
                                /> */}
                    <div className="schedule-table">
                    <div className="day-schedule">
                        <div className="day">Monday</div>
                        {teacherSchedules.map((schedule) => {
                        return (
                            <div key={schedule.id}>
                            {schedule.day === "Monday" && (
                                <div className="schedule-details">
                                <div className="left">
                                    <p>
                                    {schedule.start_time} - {schedule.end_time}
                                    </p>

                                    <b>{schedule.subject}</b>
                                    <p>{schedule.related_class}</p>
                                </div>

                                {/* <button
                                            onClick={() =>
                                                deleteClassSchedule(`${schedule.id}`)
                                            }
                                            className="delete-schedule"
                                            >
                                            Delete
                                            </button> */}
                                </div>
                            )}
                            </div>
                        );
                        })}
                    </div>
                    <div className="day-schedule">
                        <div className="day">Tuesday</div>
                        {teacherSchedules.map((schedule) => {
                        return (
                            <div key={schedule.id}>
                            {schedule.day === "Tuesday" && (
                                <div className="schedule-details">
                                <div className="left">
                                    <p>
                                    {schedule.start_time} - {schedule.end_time}
                                    </p>

                                    <b>{schedule.subject}</b>
                                    <p>{schedule.related_class}</p>
                                </div>

                                {/* <button
                                            onClick={() =>
                                                deleteClassSchedule(`${schedule.id}`)
                                            }
                                            className="delete-schedule"
                                            >
                                            Delete
                                            </button> */}
                                </div>
                            )}
                            </div>
                        );
                        })}
                    </div>
                    <div className="day-schedule">
                        <div className="day">Wednesday</div>

                        {teacherSchedules.map((schedule) => {
                        return (
                            <div key={schedule.id}>
                            {schedule.day === "Wednesday" && (
                                <div className="schedule-details">
                                <div className="left">
                                    <p>
                                    {schedule.start_time} - {schedule.end_time}
                                    </p>

                                    <b>{schedule.subject}</b>
                                    <p>{schedule.related_class}</p>
                                </div>

                                {/* <button
                                            onClick={() =>
                                                deleteClassSchedule(`${schedule.id}`)
                                            }
                                            className="delete-schedule"
                                            >
                                            Delete
                                            </button> */}
                                </div>
                            )}
                            </div>
                        );
                        })}
                    </div>
                    <div className="day-schedule">
                        <div className="day">Thursday</div>
                        {teacherSchedules.map((schedule) => {
                        return (
                            <div key={schedule.id}>
                            {schedule.day === "Thursday" && (
                                <div className="schedule-details">
                                <div className="left">
                                    <p>
                                    {schedule.start_time} - {schedule.end_time}
                                    </p>

                                    <b>{schedule.subject}</b>
                                    <p>{schedule.related_class}</p>
                                </div>

                                {/* <button
                                            onClick={() =>
                                                deleteClassSchedule(`${schedule.id}`)
                                            }
                                            className="delete-schedule"
                                            >
                                            Delete
                                            </button> */}
                                </div>
                            )}
                            </div>
                        );
                        })}
                    </div>
                    <div className="day-schedule">
                        <div className="day">Friday</div>
                        {teacherSchedules.map((schedule) => {
                        return (
                            <div key={schedule.id}>
                            {schedule.day === "Friday" && (
                                <div key={schedule.id} className="schedule-details">
                                <div className="left">
                                    <p>
                                    {schedule.start_time} - {schedule.end_time}
                                    </p>

                                    <b>{schedule.subject}</b>
                                    <p>{schedule.related_class}</p>
                                </div>

                                {/* <button
                                            onClick={() =>
                                                deleteClassSchedule(`${schedule.id}`)
                                            }
                                            className="delete-schedule"
                                            >
                                            Delete
                                            </button> */}
                                </div>
                            )}
                            </div>
                        );
                        })}
                    </div>
                    </div>
                </div>
                ) : (
                <div className="no-data">
                    <img src={NoDataImage} alt="no data" />
                    <p>No Data Found</p>
                </div>
                )}
            </div>

        )
      }
    </div>
  );
};

export default UserSchedules;
