import React from 'react'
import { Route, Routes } from 'react-router-dom'
import TeacherDashboard from '../pages/Dashboard/TeacherDashboard'
import EyfsMarks from '../pages/EYFS/EyfsMarks'
import Homework from '../pages/TeacherPages/Homework/Homework'
import UserSchedules from '../pages/Teachers/UserSchedules'

const TeacherRoutes = () => {
  return (
    <Routes>
        <Route path="/dashboard" element={<TeacherDashboard />} />
        <Route path="/eyfs-marks" element={<EyfsMarks />} />
        <Route path="/homeworks" element={<Homework />} />
        <Route path="/schedule" element={<UserSchedules />} />
    </Routes>
  )
}

export default TeacherRoutes