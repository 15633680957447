import axios from "axios";
import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { API_URL } from "../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import Multiselect from "multiselect-react-dropdown";
import { useAuthenticate } from "../../context/AuthContext";

const AssignTeacherSubject = ({ schoolTeachers, schoolSubjects, setModal }) => {
  const {schoolClasses} = useAuthenticate()
  const [loading, setLoading] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState("");

  const handleSubmit = async (e) => {
    setLoading(true);
    const selectedClassesIds = [];
    for( let i=0; i<selectedClasses.length; i++ ) {
      selectedClassesIds.push(selectedClasses[i].id);
    }
    e.preventDefault();
    const body = JSON.stringify({
      "teacher": selectedTeacher,
      "subject": selectedSubject,
      "assigned_class": selectedClassesIds
    })

    if(localStorage.getItem('access')){
      const config={
        headers:{
          'Content-Type': `application/json`,
          'Authorization':`JWT ${localStorage.getItem('access')}`,
          'Accept':'application/json'
        }
      };
      
      try{

          const res = await axios.post(`${API_URL}academics/teacher-subjects/`,body,config)
          
          setLoading(false);
          if (res.status === 201){
             
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: "Teacher's subject has been assigned successfully",
            }).then(()=>{
              window.location.reload();
            })
          }
          
      }catch(error){
        
        setLoading(false);
        if (error.response){
          for (const property in error.response.data){
            toast.error(`${property} error: ${error.response.data[property]}`)
          }
        }else if(error.message){
          toast.error("Something went wrong. Please try again")
        }
      }
    }
  };

  return (
    <div
      id="modal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal"
    >
      <div class="bg-white rounded-lg shadow-lg lg:w-1/3 w-4/5 max-h-screen overflow-auto">
        <div class="px-6 py-4 border-b text-black items-center flex justify-between">
          <h3 class="text-lg  font-semibold">Assign Teacher Subject</h3>
          <IoCloseCircleOutline
            onClick={() => setModal(false)}
            className="text-[20px] cursor-pointer"
          />
        </div>
        <div class="px-4">
          <div className="shadow mt-5 lg:mt-0 overflow-hidden sm:rounded-md bg-white">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="px-4 py-2 bg-white sm:p-6"
            >
              <div className="grid grid-cols-6 gap-2">
                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Teacher
                  </label>
                <select onChange={(e) => setSelectedTeacher(e.target.value)} name="teacher" id="" className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out">
                    <option>Select Teacher</option>
                    {schoolTeachers.map((schoolTeacher) => {
                        return (
                        <option value={schoolTeacher.staff_id} key={schoolTeacher.staff_id}>
                            {schoolTeacher?.user_account?.first_name} {schoolTeacher?.user_account?.last_name}
                        </option>
                        );
                    })}
                </select>
                  
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Subject*
                  </label>
                  <select
                    name="class_level"
                    onChange={(e) => setSelectedSubject(e.target.value)}
                    id=""
                    className="mt-1 text-black form-input block sm:text-sm sm:leading-5 w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                  >
                    <option>Select Subject</option>
                    {schoolSubjects.map((schoolSubject) => {
                        return (
                        <option value={schoolSubject.id} key={schoolSubject.id}>
                            {schoolSubject.subject_name}
                        </option>
                        );
                    })}
                  </select>
                </div>

                <div class="col-span-6">
                  <label
                    for="payouts-service-first-name"
                    class="block text-sm font-medium leading-5 text-gray-950"
                    id="payouts-service-first-name-label"
                  >
                    Classes*
                  </label>
                 
                    <Multiselect
                        onSelect={(e) => setSelectedClasses(e)}
                        avoidHighlightFirstOption={true}
                        placeholder="Select Classes"
                        name="classes"
                        options={schoolClasses} // Options to display in the dropdown
                        displayValue="class_name" // Property name to display in the dropdown options
                    />
                  
                </div>
              </div>

              {loading ? (
                <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                <button
                  type="submit"
                  value="Save"
                  id="payouts-account-submit"
                  class="mt-2 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-600"
                >
                  Add Class
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignTeacherSubject;
