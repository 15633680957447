import React, { useState } from 'react'
import Header from '../../../components/Header'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ColorRing } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { API_URL } from '../../../url';
import axios from 'axios';

const NewMessage = () => {
    const [data, setData] = useState({
        "channel":"Email"
    })
    const [loading,setLoading] = useState(false)
    const [recipient, setRecipient] = useState("")
    

    const handleFormChange = (e) => {
        setData({
            ...data, [e.target.name] : e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (localStorage.getItem("access")) {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };
          const body = JSON.stringify({
            ...data,
            recipient: recipient
          });
          try {
            const res = await axios.post(
              `${API_URL}superadmin/message/send/`,
              body,
              config
            );
    
            setLoading(false);
            
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Message Sent Successfully",
            }).then(() => {
              window.location.href = "/superadmin/messaging"
            });
          } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message)
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/";
              } else if(error.response.status === 400) {
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }else{
                
                toast.error("Something went wrong. Please try again");  
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          }
        }
      };

  return (
    <div className='main-container'>
        <Header title="New Message" />

        <form onSubmit={(e)=>handleSubmit(e)} className="border border-gray-300 rounded px-5 p-3 bg-white">
            <div className="flex flex-col gap-2">
                <label htmlFor="" className='font-bold text-sm'>Title <span className="text-red-500">*</span> </label>
                <input name='title' onChange={(e)=>handleFormChange(e)} type="text" placeholder='Enter title' className="border border-gray-300 rounded p-2" />
            </div>
            <div className="flex flex-col gap-2 mt-5">
                <label htmlFor="" className='text-sm'>Channel <span className="text-red-500">*</span> </label>
                <div className='flex items-center gap-2'>
                    <input checked type="checkbox" placeholder='Enter title' className="w-4 h-4 text-blue-600 bg-gray-200 border-gray-300 rounded focus:ring-blue-500" />
                    <span>Email</span>
                </div>

            </div>

            <div className="flex flex-col gap-2 mt-5">
                <label htmlFor="" className='font-bold text-sm'>Select Group Recipient <span className="text-red-500">*</span> </label>
                <select onChange={(e)=>setRecipient(e.target.value)} className="border border-gray-300 rounded p-2">
                    <option value="">Select Group Recipient</option>
                    <option value="School Administrators">School Administrators</option>
                    <option value="Teachers">Teachers</option>
                    <option value="Students">Students</option>
                    <option value="Parents">Parents</option>
                    <option value="Custom">Custom</option>
                </select>
                
            </div>
            {
                recipient === "Custom" && (
                    <div className="flex flex-col gap-2 mt-5">
                        <label htmlFor="" className='font-bold text-sm'>Recipient Email <span className="text-red-500">*</span> </label>
                        <input name='recipient_email' onChange={(e)=>handleFormChange(e)} type="email" placeholder='johndoe@meskuu.com' className="border border-gray-300 rounded p-2" />
                    </div>
                )
            }

            <div className="flex flex-col gap-2 mt-5">
                <label htmlFor="" className='font-bold text-sm'>Message <span className="text-red-500">*</span> </label>
                <CKEditor
                  style={{ marginTop: "10px" }}
                  editor={ClassicEditor}
                  data={data.message}
                  onChange={(event, editor) => {
                    const text = editor.getData();
                    setData({
                      ...data,
                      message: text,
                    });
                  }}
                />
            </div>
            <div className='flex justify-end mt-3'>
                  {loading ? (
                    <button
                        className='bg-gray-500 rounded cursor-wait flex items-center gap-2 px-3 py-2 text-white'
                      
                    >
                      <ColorRing
                        visible={true}
                        height="20"
                        width="20"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                      />{" "}
                      Sending Message{" "}
                    </button>
                  ) : (
                    <button type="submit" className="bg-primary px-3 py-2 rounded text-white">
                      Send Message
                    </button>
                  )}
                </div>
            
        </form>
    </div>
  )
}

export default NewMessage