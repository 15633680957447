import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import { Link } from 'react-router-dom'
import { useAuthenticate } from '../../../context/AuthContext'
import { RotatingLines } from 'react-loader-spinner'
import { API_URL } from '../../../url'
import axios from 'axios'

const Messaging = () => {
    const {user} = useAuthenticate();
    const [loadingData, setLoadingData] = useState(false);
    const [messages, setMessages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');  // Track the search query
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);

    const filterMessagesList = (searchValue) => {
        setSearch(searchValue);
        setCurrentPage(1);  // Reset to page 1 whenever search query changes
      };
    
      

    const getMessages = async (page,searchQuery) => {
        if (localStorage.getItem("access")) {
          setLoadingData(true);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };
          await axios
            .get(`${API_URL}superadmin/message/send/?page=${page}&search=${searchQuery}`, config)
            .then((response) => {
              
                setMessages(response.data?.results);
              
              setNextPage(response.data?.next);
              setPrevPage(response.data?.previous);
              setTotalPages(Math.ceil(response.data?.count / 20));
              
              setLoadingData(false);
            })
            .catch((error) => {
            setLoadingData(false)
              if (error.response.status === 401) {
                window.location.href = "/";
              }
            });
        }
      };
    

      const handleNextPage = () => {
        if (nextPage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const handlePreviousPage = () => {
        if (prevPage) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    
      const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalNumbersToShow = 3; // Number of pages to show around the current page
        const totalPagesToShow = 5; // Total pages to show at start and end
    
        let startPage = Math.max(1, currentPage - totalNumbersToShow);
        let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);
    
        // Always show the first page
        if (startPage > 1) {
            pageNumbers.push(1);
            if (startPage > 2) {
                pageNumbers.push('...');
            }
        }
    
        // Show the numbers around the current page
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
    
        // Always show the last page
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageNumbers.push('...');
            }
            pageNumbers.push(totalPages);
        }
    
        return pageNumbers.map(number => (
            <button
                key={number}
                onClick={() => number !== '...' && handlePageClick(number)}
                className={number === currentPage ? 'bg-secondary border border-secondary text-white rounded py-2 px-3 min-w-10' : 'border border-secondary text-main bg-transparent py-2 px-3 rounded min-w-10'}
            >
                {number}
            </button>
        ));
    };
    
      useEffect(() => {
        getMessages(currentPage, search);
      }, [currentPage,search]);
    

  return (
    <div className='main-container'>
        <Header title="Messaging" />
        <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-5">
        <div className="flex flex-col lg:flex-row lg:items-center gap-2">
          <input
            type="text"
            placeholder="Search by notification title"
            className="px-[10px] w-[200px] py-2 border border-gray-300 outline-none text-[12px]"
            onChange={(e)=>filterMessagesList(e.target.value)}
          />
          
        </div>
        
            <div className="flex">
              <Link
                to="/superadmin/messaging/create"
                className="px-[10px] py-2 text-[12px] text-white border bg-primary"
              >
                Add Notification
              </Link>
              
            </div>
        
      </div>

      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            {!loadingData && (
              <table className="min-w-full text-left text-sm font-light text-surface ">
                <thead className=" w-screen  font-medium">
                  <tr className="border-b border-t border-slate-200 text-black">
                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Title
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Recipient
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Channel
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Date Created
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {messages?.map((message, index) => (
                    <tr
                      key={index}
                      className="text-[12px] border-l-2 border-transparent py-3 hover:border-primary hover:bg-slate-50 "
                    >
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {message?.title}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {message?.recipient_email}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {message?.channel}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {message?.created_at}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {message?.status === "Sent" && (
                          <div className="w-max bg-green-100 text-green-600 font-bold px-[8px] py-1 text-center text-[11px]">
                            {message?.status}
                          </div>
                        )}
                        {message?.status === "Pending" && (
                          <div className="w-max bg-red-100 text-yellow-600 px-[8px] py-1 text-[11px] text-center">
                            {message?.status}
                          </div>
                        )}
                      </td>

                      <td className="px-4 py-3">
                        <Link
                          to={`/message/${message?.id}/details`}
                          className=" text-[12px] w-max px-[10px] py-2 font-[500] border border-blue-400 text-blue-400  hover:bg-blue-400 hover:text-white cursor-pointer"
                        >
                          Details
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {loadingData && (
              <div className="flex items-center justify-center py-5">
                <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
            {!loadingData && messages.length == 0 && (
              <p className="text-surface text-gray-400 py-10 text-center">
                No message found.
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-10">
        <p>
          Page {currentPage} of {totalPages}
        </p>

        <div className="flex flex-col gap-2 items-start md:px-5 md:flex-row">
          <button className={`border border-primary py-2 px-4 ${!prevPage ? "text-primary bg-white":"text-white bg-primary"}`} onClick={handlePreviousPage} disabled={!prevPage}>
            Previous
          </button>
          {renderPageNumbers()}
          <button className={`border border-primary py-2 px-4 ${!nextPage ? "text-primary bg-white":"text-white bg-primary"}`} onClick={handleNextPage} disabled={!nextPage}>
            Next
          </button>
          
        </div>
      </div>
    </div>
  )
}

export default Messaging